
<template>
  <v-snackbar v-model="show" :color="color" :top='true'>
    {{ message }} 
       <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="show = false"
        >
        <v-icon> fa fa-times</v-icon>      
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      colors:{
          'info': '#11cdef',
          'success': "#2dce89",
          'warning': "#fb6340",
          'danger':'#f5365c'
        },  
      color: '' 
    }
  },

  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'showMessage') {
        this.message = state.snackbar.content
        this.color = this.colors[state.snackbar.color]
        this.show = true
      }
    })
  }
}
</script>