/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
 import store from '../../store'
 import DashboardLayout from "../views/Layout/DashboardLayout.vue";
 import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
 
// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Kanban.vue");

const Settings = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Account/Settings.vue");
const Charts = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Analytics/Charts.vue");
const Templates = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Templates/Templates.vue");
const Login = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Authentication/Login.vue"
  );
const Analytics = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Analytics/Analytics.vue"
  );

const Users = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Users/Users.vue"
  );

const Invoices = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Invoices/Invoices.vue"
);

const Freelancers = () =>
import(
  /* webpackChunkName: "pages" */ "@/views/Freelancers/Freelancers.vue"
);
const Preview = () =>
  import(
    /* webpackChunkName: "pages" */ "@/views/Dashboard/Preview.vue"
  );

// let userPages = {
//   path: "/",
//   component: DashboardLayout,
//   name: "Users",
//   children: [


//   ],
// };


let authPages = {
  path: "/login",
  component: AuthCoverLayout,
  name: "Login",
  children: [
    {
        path: '/login',
        meta: {
            // hideFooter: true,
          name: 'login',
          title: 'WordFarm | Login',
          requiresAuth: false
        },
        component: Login,
        // redirect if already signed in
        beforeEnter: (to, from, next) => {
          if (store.getters.authorized) {
            next('/')
          } else {
            next()
          }
        }
      },
       {
        path: '/resetPassword/',
        meta: {
          name: 'Reset Password',
          title: 'WordFarm | Reset Password',
          requiresAuth: false

        },
        component: () => import(`@/views/Authentication/ResetPassword.vue`),
        beforeEnter: (to, from, next) => {
          if (store.getters.authorized) {
            next('/')
          } else {
            next()
          }
        }
  },
  ],
};


export default [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/orders",
    component: DashboardLayout,
    children: [
      {
        path: "orders",
        name: "Default",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
          requiresAuth: true,
          title: "WordFarm | Orders",

        },
      },
      {
        path: "templates",
        name: "Templates",
        component: Templates,
        meta: {
          requiresAuth: true,
          title: "WordFarm | Templates",

        },
      },
      {
        path: "users",
        name: "Users",
        component: Users,
        meta: {
          requiresAdmin: true,
          title: "WordFarm | Users",

        },
      },
      {
        path: "freelancers",
        name: "Freelancers",
        component: Freelancers,
        meta: {
          requiresAdmin: true,
          title: "WordFarm | Freelancers",

        },
      },
      {
        path: "invoices",
        name: "Invoices",
        component: Invoices,
        meta: {
          requiresAuth: true,
          title: "WordFarm | Invoices",

        },
      },
      {
        path: "analytics",
        name: "Analytics",
        component: Analytics,
        meta: {
          requiresAdmin: true,
          title: "WordFarm | Analytics",

        },
      },
      {
        path: "/settings",
        name: "Settings",
        requiresAuth: false,
        component: Settings,
        meta: {
          groupName: "Pages",
          requiresAuth: true,
          title: "WordFarm | Settings",

        },
    },
    {
      path: '/preview',
      name: 'Preview',
      component: Preview,

      meta: {
        title: 'WordFarm | Preview',
        requiresAuth: true

      },
    },
   
      {
        path: "analytics",
        name: "Analytics",
        requiresAuth: true,
        component: Charts,
        meta: {
          groupName: "Analytics",
          requiresAuth: true,

        },
      },
     
   
    ],
  },
  {
    path: '/acceptInvite/:inviteCode',
    meta: {
      name: 'Accept Invite',
    },
    component: () => import(`@/views/WordFarmInvite/AcceptInvite.vue`),
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next('/')
      } else {
        next()
      }
    }

  },
 
  {
    path: '/logout',
    meta: {
      name: 'logout',
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
        store.dispatch('logout')
        // next('/')
    }

  },
  authPages,
];




//  export default [
 
//    // This  allows you to have pages apart of the app but no rendered inside the dash
 
//    {
//      path: '/resetPassword/',
//      meta: {
//        name: 'Reset Password',
//        title: 'WordFarm | Reset Password',
//        requiresAuth: false
 
//      },
//      component: () => import(`@/views/ResetPassword.vue`),
//      beforeEnter: (to, from, next) => {
//        if (store.getters.authorized) {
//          next('/')
//        } else {
//          next()
//        }
//      }
//    },
 
//    {
//      path: '/logout',
//      meta: {
//        name: 'logout',
//        requiresAuth: true
//      },
//      beforeEnter: (to, from, next) => {
//          store.dispatch('logout')
//          next('/')
//      }
 
//    },
 
//    {
//      path: '/acceptInvite/:inviteCode',
//      meta: {
//        name: 'Accept Invite',
//      },
//      component: () => import(`@/views/AcceptInvite.vue`),
//      beforeEnter: (to, from, next) => {
//        if (store.getters.authorized) {
//          next('/')
//        } else {
//          next()
//        }
//      }
 
//    },
//    // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
//    {
//      path: '/',
//      meta: {
//        name: 'Dashboard View',
       
//      },
//      redirect: () => {
//        return "/dashboard"
//      },
//      component: () => import(`@/views/DashboardView.vue`),
//      children: [
//        {
//          path: '/dashboard',
//          name: 'Orders',
//          meta: {
//            title: 'WordFarm | Orders',
//            requiresAuth: true
 
//          },
//          component: () => {
//            if(store.state.permissions == "Freelancer")
//               return import(`@/views/FreelancerOrders.vue`)
//            else if (store.state.permissions == "Countrymanager")
//              return import(`@/views/CountryManagerOrders.vue`)
//            else if (store.state.permissions == "Admin")
//              return import(`@/views/Orders.vue`)
//          } 
//        },
//        {
//          path: '/settings',
//          name: 'Settings',
//          meta: {
//            title: 'WordFarm | Settings',
//            requiresAuth: true
 
//          },
//          component: () =>  {
//            if(store.state.permissions == "Freelancer")
//               return import(`@/views/FreelancerSettings.vue`)
//          },
//        },
    
//        {
//          path: '/freelancers',
//          name: 'Freelancers',
//          meta: {
//            title: 'WordFarm | Freelancers',
//            requiresAdmin: true
 
//          },
//          component: () => import(`@/views/Freelancers.vue`)
//        },
//        {
//          path: '/users',
//          name: 'Users',
//          meta: {
//            title: 'WordFarm | Users',
//            requiresAdmin: true
 
//          },
//          component: () => import(`@/views/Users.vue`)
//        },
//        {
//          path: '/invoices',
//          name: 'Invoices',
//          meta: {
//            title: 'WordFarm | Invoices',
//            requiresAuth: true
 
//          },
//          component: () => import(`@/views/FreelancerInvoices.vue`)
//        },
//        {
//          path: '/templates',
//          name: 'Templates',
//          meta: {
//            title: 'WordFarm | Templates',
//            requiresAuth: true
 
//          },
//          component: () => import(`@/views/Templates.vue`)
//        },
//        {
//          path: '/content',
//          name: 'Content',
//          meta: {
//            title: 'WordFarm | Content',
//            requiresAuth: true
 
//          },
//          component: () => import(`@/views/EditContent.vue`)
//        },
//        {
//          path: '/analytics',
//          name: 'Analytics',
//          meta: {
//            title: 'WordFarm | Analytics',
//            requiresAdmin: true
 
//          },
//          component: () => import(`@/views/Analytics.vue`)
//        },
 
   
//      ]
//    }
//  ]
 